import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import queryString from 'query-string';
import { Route, Routes } from "react-router-dom";
import { PanelMainMenu } from '../../../components/panel/PanelMainMenu';
import { LogoContainerTiendanube } from '../../../components/units/LogoContainer';
import { GridContainer } from '../../../components/panel/grid/GridContainer';
import { getStoreName } from '../../../services/onboarding.service';
import { useAuthStore,useonboardingStore } from '../../../store';
import { DeliveryTimesConfig } from '../../../pages/panel/tiendanube/DeliveryTimesConfig';
import { PanelCreationForm } from '../../../components/panel/tiendanube/PanelCreationForm';


export default function PanelRoute() {
  const location = useLocation();
  const [storeName, setStoreName] = useState("");
  const addAuth = useAuthStore((state) => state.addAuthData);
  const addData = useonboardingStore((state) => state.addData);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const { store = "" } = queryString.parse(location.search);
  const { token = "" } = queryString.parse(location.search);
  const [isLoading,setIsLoading] = useState(true)

  return (
    <div className={`container-fluid ${ecommerce}`} id="panel">
      <div className="row">
        <div className="col col-12">
          {ecommerce === "tiendanube" && <LogoContainerTiendanube />}
        </div>
      </div>
      <div className="row row-grid">
        <div className="col-2 main-nav">
          <PanelMainMenu ecommerce={ecommerce} />
        </div>
        <div className="col-10 content-wrapper">
          <Routes>
            <Route path="order/*" element={<GridContainer />} />
            <Route path={`delivery-promise`} element={<DeliveryTimesConfig />} />
            <Route path={`store`} element={<PanelCreationForm />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
