import React from 'react';
import ReactDOM from 'react-dom';
import {AppRoutes} from './routes/AppRoutes'
import reportWebVitals from './reportWebVitals';

import './App.scss';


ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
