import React from "react";
import ReactTooltip from "react-tooltip";
import info from "../../../assets/UI/info.svg";
import { useAuthStore } from "../../../store";

export const ButtonBig = ({
  type,
  btnTxt,
  showSpinner,
  isDisabled,
  onClickFn,
  extraClass,
}) => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  return (
    <div className="d-grid gap-2">
      {isDisabled ? (
        <button
          type={type}
          className={`btn btn-primary ${ecommerce} block mt-3 mt-xxl-4 mb-3 ${extraClass}`}
          onClick={onClickFn}
          disabled
        >
          {showSpinner ? (
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          ) : (
            btnTxt
          )}
        </button>
      ) : (
        <button
          type={type}
          className={`btn btn-primary ${ecommerce} block mt-3 mt-xxl-4 mb-3 ${extraClass}`}
          onClick={onClickFn}
        >
          {showSpinner ? (
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          ) : (
            btnTxt
          )}
        </button>
      )}
    </div>
  );
};

export const ButtonSmall = ({
  type,
  btnTxt,
  showSpinner,
  isDisabled,
  onClickFn,
  extraClass,
  spinnerStyle,
  btnId
}) => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  return (
    <div className="d-flex justify-content-end align-items-center">
      {isDisabled ? (
        <button
          type={type}
          className={`btn btn-primary ${ecommerce} small ${extraClass}`}
          onClick={onClickFn}
          id={btnId}
          disabled
        >
          {showSpinner ? (
            <div
              className={
                spinnerStyle
                  ? `${spinnerStyle}`
                  : "spinner-grow spinner-grow-sm"
              }
              role="status"
            >
              <span className="visually-hidden">Cargando...</span>
            </div>
          ) : (
            btnTxt
          )}
        </button>
      ) : (
        <button
          type={type}
          className={`btn btn-primary ${ecommerce} small ${extraClass}`}
          onClick={onClickFn}
        >
          {showSpinner ? (
            <div
              className={
                spinnerStyle ? spinnerStyle : "spinner-grow spinner-grow-sm"
              }
              role="status"
            >
              <span className="visually-hidden">Cargando...</span>
            </div>
          ) : (
            btnTxt
          )}
        </button>
      )}
    </div>
  );
          };



export const InformationTooltip = ({
  tooltipTxt,
  isDisabled,
  position,
  extraClassIcon,
  extraClassContainer,
  extraClassReactTooltip,
  idName
}) => {
  return (
    <div className={`infoTooltip ${extraClassContainer}`}>
      <img
        src={info}
        alt="Información"
        className={`icon-child ${extraClassIcon}`}
        data-tip
        data-for={idName}
      />
      <ReactTooltip 
      id={idName} 
      place={position} 
      effect="solid"
      className={`${extraClassReactTooltip}`}
      >
        {tooltipTxt}
      </ReactTooltip>
    </div>
  );
};

export const ButtonTag= ({
  tag,
  onClickFn,
  innerText,
}) =>{
  return (
    <button
      className={`badge panel-format ${tag}`}
      onClick={onClickFn}
    >
      {innerText}                                                                     
    </button>
  );
}

export const ButtonAndToolTip = ({
  ecommerce,
  isDisabled,
  btnTxt,
  showSpinner,
  onClickFn,
  tooltipTxt,
  tooltipPosition,
  extraClassButton,
  extraClassContainerTooltip,
  btnId,
  tooltipId
})=>{
  return(
    <div className='btnntooltip'>
      <ButtonSmall
        ecommerce={ecommerce}
        type="button"
        idName={btnId}
        extraClassButton={extraClassButton}
        btnTxt={btnTxt}
        showSpinner={showSpinner}
        isDisabled={isDisabled}
        onClickFn={onClickFn}
      />
      <InformationTooltip
          tooltipTxt={tooltipTxt}
          position={tooltipPosition}
          extraClassContainer={extraClassContainerTooltip}
          idName={tooltipId}
      />
    </div>
  )
}