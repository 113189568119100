import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

//Auth

let auth = set => ({
    ecommerce: {name:''},
    setEcommerce: (data) => set(state => ({ ecommerce: data })),
    cleanEcommerce: () => set(state => ({ ecommerce: {} })),
    authData: {},
    addAuthData: (data) => set(state => ({ authData: data })),
    removeAuthData: () => set(state => ({ authData: {} }))
});

auth = persist(auth, {name: 'authData'});
auth = devtools(auth);


//Onboarding

let onboarding = (set) => ({
    onboardingData: {},
    addData: (data) => set( state => ({ onboardingData: data }) ),
    removeOnboardingData: () => set( state => ({ onboardingData: {} }))
});

onboarding = persist(onboarding, {name: 'onboardingData'});
onboarding = devtools(onboarding);



export const useAuthStore = create(auth);
export const useonboardingStore = create(onboarding);

