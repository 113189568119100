import React from 'react';
import urbanoLogo from '../../assets/Store/urbanoLogo.png'
import tiendanubeLogo from '../../assets/Store/tiendanubeLogo.png'


export const LogoContainerTiendanube = () => {
    return (
        <div className="logo-container">
            <img src={urbanoLogo} alt="urbano logo" />
            <img src={tiendanubeLogo} alt="tiendanube logo" />
        </div>
    )
}
