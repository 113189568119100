import React, { useEffect, useState } from "react";
import { setDeliveryTime } from "../../services/onboarding.service";
import { showError, showSuccess } from "../../services/shared/alert.service";
import { useAuthStore, useonboardingStore } from "../../store";
import { ButtonSmall, InformationTooltip } from "../units/shared/Button";
import { InputsSolidPlaceHolder, InputsRadio } from "../units/shared/Inputs";
import {capitalizeFirstLetter, forcePositive, mapFee, removeLastChar} from "../../services/shared/utils.service";

export const TimeFormPanel = () => {
  const addData = useonboardingStore((state) => state.addData);
  const store = useonboardingStore((state) => state.onboardingData.location);
  const auth = useAuthStore((state) => state.authData);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const [isLoading, setIsLoading] = useState(false);
  const [canPass,setCanPass] = useState(false)
  const [body, setBody] = useState({
    createAt: store?.createAt,
    rate: store?.rate,
    ratePercent: store?.ratePercent || 0,
    isPositive: ""
  });  

  useEffect(() => {
    setCanPass(false)
    if(body.createAt && body.rate)
    setCanPass(true)
    if(body.rate === 'customDynamic'){
    setCanPass(false)
      if(body.ratePercent>0){
        setCanPass(true)
      }
    }
  }, [body,store])
  

  useEffect(() => {
    if (store?.createAt) {
      setBody({ ...body, createAt: store.createAt });
    }
    if (store?.rate) {
      setBody({ ...body, rate: store.rate });
    }
    if (store?.ratePercent) {
      const values = mapFee(store.ratePercent, store?.isPositive);
      setBody({ ...body, ratePercent: values.number, isPositive: values.operator });
    }
  }, [store]);

  const handlePercentageChange = (e) => {
    let {value} = e.target
    if (value > 100) value = 100;
    if (value < 0) value = 0;
    if (isNaN(value)) value = removeLastChar(value);
    
    setBody({ ...body, ratePercent: value });
  };

  const handlePercentSignChange = (isPositiveParam, e) => {
    e.preventDefault();
    setBody({ ...body, isPositive: isPositiveParam });
  };

  const handleRadioChange = (e) =>{
    if (e.target.checked) {
      if (
        e.target.id === "packed" ||
        e.target.id === "paid" ||
        e.target.id === "created"
      ) {
        setBody({ ...body, createAt: e.target.value });
      }
      if (
        e.target.id === "dynamic" ||
        e.target.id === "flat" ||
        e.target.id === "customDynamic"
      ) {
        setBody({ ...body, rate: e.target.value });
      }
    }
  };
  
  const setTimes = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let modifiedRateType = {
        createAt: body.createAt,
        rate: body.rate,
        ratePercent: Number(body.ratePercent),
        isPositive: body.isPositive
      }
      const result = await setDeliveryTime(modifiedRateType, auth.user.storeId);
      if (result.status===200) { 
        addData({
          location: {
            ...store,
            createAt: body.createAt,
            rate: body.rate,
            ratePercent: body.ratePercent,
            isPositive: body.isPositive
          },
        });
        showSuccess(result.data.message);
      }
      if (result.error) {
        showError(result.error);
      }
      setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError("Ocurrió un error en la conexión");
      }
    }
  

  return (
    <div className="row">
      <div className="col-6 mb-3">
      <form className="row mt-3 mt-xxl-3" onSubmit={setTimes}>
        <div className="col-12 mb-1 ">
            <h2 className="step-title">Sincronización con {capitalizeFirstLetter(ecommerce)}</h2>
            <h3 className="step-subtitle">Selecciona a partir de qué acción deseas que se cree la guía en Urbano.</h3>
        </div>
          <InputsRadio
            value="packed"
            id="packed"
            name="createAt"
            labelTxt="Cuando la orden se marque como empaquetada en Tiendanube."
            onChangeFn={handleRadioChange}
            checked={body.createAt === "packed"}
          />
          <InputsRadio
            value="paid"
            id="paid"
            name="createAt"
            labelTxt="Cuando la orden se paga en Tiendanube."
            onChangeFn={handleRadioChange}
            checked={body.createAt === "paid"}
          />
          <InputsRadio
            value="created"
            id="created"
            name="createAt"
            labelTxt="Cuando la orden se crea en Tiendanube."
            onChangeFn={handleRadioChange}
            checked={body.createAt === "created"}
          />
        <div className="col-12 mt-5">
          <h2 className="step-title">Tarifa</h2>  
          <h3 className="step-subtitle">Seleccionar tarifa disponible.</h3>
        </div>
        <div className="col-6 mb-3">
          <div className="infoTooltip">
            <InputsRadio
              value="dynamic"
              id="dynamic"
              name="rate"
              labelTxt="Tarifa dinámica de Urbano."
              onChangeFn={handleRadioChange}
              checked={body.rate === "dynamic"}
            />
            <InformationTooltip
              tooltipTxt={`Calcularemos el costo del envío según peso, volumen y distancia de forma automática..`}
              position="top"
              extraClassContainer="tooltipBubble"
              idName="dynamicTooltip"
            />
          </div>

          <div className="infoTooltip">
            <InputsRadio
              value="flat"
              id="flat"
              name="rate"
              labelTxt="Tarifa fija de servicio."
              onChangeFn={handleRadioChange}
              checked={body.rate === "flat"}
            />
            <InformationTooltip
              tooltipTxt={`Podrás configurar la tarifa fija desde tu tienda. La misma aplicará para todos tus envíos.`}
              position="top"
              extraClassContainer="tooltipBubble"
              idName="flatTooltip"
            />
          </div>

          <div className="infoTooltip">
            <InputsRadio
              value="customDynamic"
              id="customDynamic"
              name="rate"
              labelTxt="Tarifa dinámica ajustada."
              onChangeFn={handleRadioChange}
              checked={body.rate === "customDynamic"}
            />
            <InformationTooltip
              tooltipTxt={`Se aplicará la tarifa dinámica con un incremento o descuento según lo selecciones a continuación`}
              position="top"
              extraClassContainer="tooltipBubble"
              idName="customDynamicTooltip"
            />
          </div>

          {body.rate === "customDynamic" && (
            <div className="containerRate">
              <div className="buttonsRateContainer">
                <ButtonSmall
                  type="button"
                  btnTxt="+"
                  className="btnToBePressed"
                  onClickFn={(e) => handlePercentSignChange(true, e)}
                  extraClass={`btn-small-rate left-border ${
                    body.isPositive ? "btn-pressedDown" : "btn-released"
                  }`}
                  showSpinner={isLoading}
                />
                <ButtonSmall
                  type="button"
                  btnTxt="-"
                  onClickFn={(e) => handlePercentSignChange(false, e)}
                  extraClass={`btn-small-rate right-border ${
                    !body.isPositive ? "btn-pressedDown" : "btn-released"
                  }`}
                  showSpinner={isLoading}
                />
                <InputsSolidPlaceHolder
                  type="text"
                  id="rateInput"
                  value={body.ratePercent}
                  onChangeFn={handlePercentageChange}
                  unit="%"
                  extraClassInput='type-b'
                  extraClassUnit='type-b'
                />
              </div>
                <span className="doubleButtonsText">
                  {body.ratePercent ? 
                    <>{`Se aplicará un ${body.ratePercent}% de `} 
                      <strong>
                        {`${body.isPositive ? 'incremento' : 'descuento'}`}
                      </strong>
                    </>
                      : <>Ingrese un valor </>}
                  
                  {` sobre la tarifa de Urbano.`}
                </span>
            </div>
          ) }
          <ButtonSmall
            type="submit"
            btnTxt="Siguiente"
            extraClass="blackButton timeFormPanel--submit"
            showSpinner={isLoading}
            spinnerStyle="spinner-border spinner-border-sm text-danger"
            isDisabled={!canPass}
          />
        </div>
      </form>
      </div>
    </div>

  );
};
