import React, { useState,useEffect } from "react";
import { OnboardingHeader } from "../../../components";
import { useAuthStore } from '../../../store'
import { ButtonSmall } from "../../../components/units/shared/Button";
import { capitalizeFirstLetter } from "../../../services/shared/utils.service";
// import { useonboardingStore } from '../../store';

export const Finish = () => {
  // const store = useonboardingStore((state) => state.onboardingData.store);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const auth = useAuthStore((state) => state.authData);
  const dummyStore={
    createAt: 'flat',
  }
  const ecommerceUrls = {
    tiendanube: {
        configLink: `${auth.user.storeUrl}/admin/preferences/checkout/`,
        goToStoreLink: `${auth.user.storeUrl}/admin`,
    }
}
  const [body, setBody] = useState({
    createAt: "",
  });

  useEffect(() => {
    if (dummyStore?.createAt) {
        setBody({ ...body, createAt: dummyStore.createAt});
    }
  }, []);

  const redirect = (url) => {
    window.location.href = `${url}`;
  };

  
  return (
    <div className={`container-fluid bg-${ecommerce}`} id="finish">
      <OnboardingHeader ecommerce={ecommerce} step={3} />
      <div className="row content p-2 pt-5">
        <div className="col-12 content-wrapper finishContainer">
          <h1 className="finish-main-title">¡Listo, guardamos tus datos!</h1>
          <h2 className="finish-subtitle">
            Recuerda que siempre puedes volver a editar las configuraciones que
            realizaste en este proceso de instalación.
          </h2>
          <div className="next-steps">
            {
              body.createAt==='flat' ? 
              <p className="finish-small-title finish-small-goodbye">
                Recordá activar el teléfono como obligatorio en tu checkout. {" "}
                <p>
                  Si aún no lo hiciste,
                  <button
                    type="button"
                    onClick={() => redirect(ecommerceUrls[ecommerce].configLink)}
                    className="btn-link fw-bold finish-link"
                  >
                    Configúralo aquí.
                  </button>
                </p>
              </p> 
              : 
              <p className="finish-small-title finish-small-goodbye">
              Para terminar de tener configurada tu cuenta, 
              <p>debes tener tu télefono <strong>obligatoriamente</strong> verificado</p>
              <p>
                  
                <button
                  type="button"
                  onClick={() => redirect(ecommerceUrls[ecommerce].configLink)}
                  className="btn-link fw-bold finish-link"
                >
                  Ajustalo aquí.
                </button>
              </p>
            </p> 
            }
            

          </div>
          <ButtonSmall
            type="button"
            btnTxt="Volver a la Tienda"
            showSpinner={false}
            isDisabled={false}
            extraClass="blackButton"
            onClickFn={() => redirect(ecommerceUrls[ecommerce].goToStoreLink)}
          />
        </div>
      </div>
    </div>
  );
};
