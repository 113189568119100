import React, { useState } from "react";
import { useNavigate} from 'react-router-dom'
import { loginService } from "../services/onboarding.service";
import { showError, showSuccess } from "../services/shared/alert.service";
import { useAuthStore } from "../store";
import { ButtonBig } from "./units/shared/Button";
import { InputsSimple } from "./units/shared/Inputs";

export const LoginForm = () => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const [isLoading, setIsLoading] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiToken, setApiToken] = useState("");
  let navigate = useNavigate();
  const addAuth = useAuthStore((state) => state.addAuthData);
  const user = useAuthStore((state) => state.authData.user);

  const handleChange = (e) => {
    switch (e.target.id) {
      case "apiKey":
        setApiKey(e.target.value.trim());
        break;
      case "apiToken":
        setApiToken(e.target.value.trim());
        break;
      default:
        break;
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (apiKey && apiToken) {
      try {
        const result = await loginService(
          apiKey,
          apiToken,
          user.storeId,
          ecommerce
        );
        if (result && result.success) {
          addAuth({
            user: {
              ...user,
              shipper: apiKey,
              password: apiToken,
            },
          });
          showSuccess(result.message);
          navigate(`/urbano/${ecommerce}/creation`)
        }
        if (result.error) {
          showError(result.error);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if(!(user?.storeId))showError('Ocurrió un error relacionado a su ID de tienda')
        else showError("Ocurrió un error en la conexión");
      }
    } else {
      setIsLoading(false);
      showError("Verifique haber completado todos los campos");
    }
  };

  return (
    <form onSubmit={signIn}>
      <InputsSimple
        value={apiKey}
        id="apiKey"
        type="text"
        placeholder="1234"
        labelTxt="API Key"
        onChangeFn={handleChange}

      />

      <InputsSimple
        value={apiToken}
        id="apiToken"
        type="text"
        placeholder="1234567891011"
        labelTxt="API Token"
        onChangeFn={handleChange}
      />

      <ButtonBig
        type="submit"
        btnTxt="Ingresar"
        showSpinner={isLoading}
        isDisabled={apiKey && apiToken ? false : true}
        extraClass="blackButton"
      />
    </form>
  );
};
