import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { GridSetter } from './GridSetter';
import { useAuthStore } from '../../../store';
import {
  getOrders,
  getMockOrders,
} from "../../../services/panel.service";
import { useLocation } from "react-router";
import queryString from 'query-string';


export const GridContainer = () => {
  const location = useLocation();
  const [tabSelected, setTabSelected] = useState("all");




  return (
    <div className="grid-container">
      <Tabs defaultActiveKey="all" id="orders-tab" onSelect={(e) => setTabSelected(e)}>
        <Tab eventKey="all" title="Todas">
          {tabSelected === "all" && (
            <GridSetter tab={tabSelected}/>
          )}
        </Tab>

        <Tab eventKey="pending" title="Pendientes">
          {tabSelected === "pending" && (
            <GridSetter tab={tabSelected}/>
          )}
        </Tab>

        <Tab eventKey="created" title="Confirmadas">
          {tabSelected === "created" && (
            <GridSetter tab={tabSelected} />
          )}
        </Tab>

        <Tab eventKey="delivered" title="Entregadas">
          {tabSelected === "delivered" && (
            <GridSetter tab={tabSelected}/>
          )}
        </Tab>

        <Tab eventKey="issue" title="Con problemas">
          {tabSelected === "issue" && (
            <GridSetter tab={tabSelected}/>
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
