import { post as restPost, get as restGet } from '../services/shared/rest.service';

export const getStoreName = async (storeId) => {
    try {
        const endpoint = `/api/v1/onboarding/initial-state?store=${storeId}`;
        const result = await restGet(endpoint);
        return result.data;
    } catch (error) {
        return error
    }
};


export const loginService = async (email, password, storeId) => {
    try {
        const endpoint = `/api/v1/onboarding/authentication?store=${storeId}`;
        const body = {
            shipper: email,
            password: password
        };
        const result = await restPost(endpoint, body);
        return result.data;
    } catch(error) {
        return error;
    }
};

export const getLocations = async (storeId) => {
    try {
        const endpoint = `/api/v1/onboarding/locations?store=${storeId}`; //ENDPOINT DE SHOPIFY
        const result = await restGet(endpoint);
        return result.data;
    } catch (error) {
        return error
    }
};

export const configLocation = async (params, storeId, ecommerce) => {
    let endpointURL ="/api/v1/onboarding/locations?store=";

    if(ecommerce==="urbano"){
        endpointURL='/api/v1/onboarding/locations?store='
    }
    try {
        const endpoint = `${endpointURL}${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        console.error('An error has ocurred during configLocation, check: ',error)
        return error;
    }

};

export const configDeliveryPromise = async (params, storeId) => {
    try {
        const endpoint = `/api/v1/onboarding/locations?store=${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        return error;
    }
};

export const createStorehouse = async (params, storeId) => {

    try {
        const endpoint = `/api/v1/onboarding/locations?store=${storeId}`;
        const result = await restPost(endpoint, params);
        return result;

    } catch (error) {
        return error;
    }
};



export const setDeliveryTime = async (body, storeId) => {

    try {
        const endpoint = `/api/v1/onboarding/ship-creation?store=${storeId}`;
        const result = await restPost(endpoint, body);
        return result;

    } catch (error) {
        return error;
    }
};

export const validateLogin = (body) =>{
    if (
        body.location.province &&
        body.location.city &&
        body.location.street &&
        body.location.externalNumber &&
        body.location.postalCode &&
        body.location.manager &&
        body.location.notes
      )
    return true
    else{ 
        console.error('Missing validate params')
        
        return false
    }
}