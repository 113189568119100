import React from 'react';
import { NavLink } from "react-router-dom";
import { FiBox, FiShoppingCart, FiCheckSquare } from "react-icons/fi";
import { useAuthStore } from '../../store';

import { useLocation } from "react-router";
import queryString from 'query-string';


export const PanelMainMenu = () => {
    const location = useLocation()
    const { store = "" } = queryString.parse(location.search);
    const { token = "" } = queryString.parse(location.search);
    const ecommerce = useAuthStore((state) => state.ecommerce.name);

    const tiendanubePages = [
        { title: "Órdenes", routeInfo: `order?store=${store}&token=${token}`, icon: <FiBox/> },
        { title: "Sincronización y tarifas", routeInfo: `delivery-promise?store=${store}&token=${token}`, icon: <FiCheckSquare/> },
        { title: "Tienda", routeInfo: `store?store=${store}&token=${token}`, icon: <FiShoppingCart/> },
    ];

    return (    
        <>
            {ecommerce === "tiendanube" &&
                <div className="panel-menu">
                    {tiendanubePages.map( (navItem, index) => (
                        <NavLink to={navItem.routeInfo} activeclassname={`menu-selected ${ecommerce}`} key={index}>
                            {navItem.icon} {navItem.title}
                        </NavLink>)
                    )}
                </div>
            }
        </>
    )
}