import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const PanelActionsModal = ({
  ordersSelected,
  ordersIdSelected,
  handleClose,
  printMassiveOrders,
  deliverOrdersAgain,
  panelTab,
  ecommerce
}) => {
  const [actionSelected, setActionSelected] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({});

  return (
    <>
      <Modal
        className="rounded-4"
        id="panel-massive-modal"
        show={true}
        backdrop={true}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title medium-text">
            {!actionSelected
              ? "Operaciones posibles"
              : confirmationProps.titleText}
          </Modal.Title>
        </Modal.Header>

        {!actionSelected ? (
          <Modal.Body className="modal-body">
            {
              <>
                <Button
                  className={`btn-primary  ${ecommerce} block mb-3`}
                  variant="none"
                  onClick={() => {
                    setConfirmationProps({
                      mainTask: printMassiveOrders,
                      handleClose: handleClose,
                      titleText: ordersSelected.length>1 ? `Se imprimirán ${ordersSelected.length} etiquetas` : `Se imprimirá 1 etiqueta`,
                      bodyText: "",
                      btnText: "Imprimir",
                      ordersSelected: ordersSelected,
                    });
                    setActionSelected(true);
                  }}
                >
                  Imprimir etiquetas
                </Button>

                <Button
                  className={`btn-outline-primary ${ecommerce} block`}
                  variant="none"
                  onClick={() => {
                    handleClose();
                    setActionSelected(true);
                  }}
                >
                  Cerrar
                </Button>
              </>
            }

            {(panelTab === "created" || panelTab === "delivered") && (
              <Button
              className={`btn-primary ${ecommerce} block`}
                variant="none"
                onClick={() => {
                  setConfirmationProps({
                    mainTask: deliverOrdersAgain,
                    titleText: "Regenerar Etiqueta",
                    handleClose: "Volver a solicitar repartidores",
                    bodyText: "¿Quieres volver a solicitar las recolecciones?",
                    btnText: "Regenerar Etiqueta",
                    ordersSelected: ordersIdSelected,
                  });
                  setActionSelected(true);
                }}
                disabled={panelTab === "pending" && true}
              >
                Regenerar Etiqueta
              </Button>
            )}
          </Modal.Body>
        ) : (
          <>
            <Modal.Body className="modal-body small-title">
              {confirmationProps.bodyText}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center border-0">
              <Button
                className={`btn-outline-primary ${ecommerce}`}
                variant="none"
                onClick={() => {
                  setActionSelected(false);
                  setConfirmationProps({});
                }}
              >
                Cerrar
              </Button>
              <Button
                className={`btn-primary ${ecommerce}`}
                variant="none"
                onClick={() => {
                  confirmationProps?.mainTask(ordersIdSelected);
                  handleClose();
                }}
              >
                {confirmationProps?.btnText}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};
