import { showError } from "./alert.service";

export const setEcommerceURL = (location) => {
    let ecommerce = "";
    if (location.pathname.includes("tiendanube")) {
      ecommerce = "tiendanube";
    } 
    return ecommerce;
}

export const validateEmail = (email) => {
    const rgx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return rgx.test(String(email).toLowerCase());
}

export const validatePhone = (phoneNumber) => {
    const re = /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/g;
    return re.test(phoneNumber);
}

export const validateLength = (inputData) => {
    if(inputData.length !==0 && inputData.length < 90) return true
}

export const checkLength = (e) => {
  if (!validateLength(e.target.value)) {
    if(e.target.id === "notes") {
      showError("notas adicionales no puede estar vacío ni exceder los 900 caracteres");
    } else {
      showError(`${e.target.placeholder} no puede no puede estar vacío ni exceder los 900 caracteres`);
    }
  }
};

export const checkPhoneFormat = (e) => {
  if (!validatePhone(e.target.value)) {
    showError("El formato de teléfono ingresado no es correcto");
  }
};

export const checkEmailFormat = (e) => {
  if (!validateEmail(e.target.value)) {
    showError("El formato de correo electrónico ingresado no es correcto");
  }
};

export const enableButton = (
  inputsToCheckArray,
  inputEmail,
  inputPhone,
  setAllValidated
) => {
  let confirmed = inputsToCheckArray.every((input) => validateLength(input));
  let emailConfirmed = validateEmail(inputEmail);
  let phoneConfirmed = validatePhone(inputPhone);
  if (confirmed && emailConfirmed && phoneConfirmed) {
    setAllValidated(true);
  } else {
    setAllValidated(false);
  }
};

export const formatPhoneNumber = (plainNum) => {
    const regex = /\+{1,}/gi;
    if(!plainNum) return
    if(regex.test(plainNum)){
        let cleaner = plainNum.replace(regex, "").trim();
        return `+${cleaner}`
    }
    return `+${plainNum}`
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const removeLastChar = (string) =>{
  return string.substr(0,string.length-1)
}

export const getLastChar = (string) =>{
  return string.charAt(string.length-1)
}

export const nullChecker = cell => (!cell ? "-" : cell);

export const forcePositive = (value) => {
  let modifiedValue = value
  if (value > 100) modifiedValue = 100;
  if (value < 0) modifiedValue = 0;
  if (isNaN(modifiedValue)) modifiedValue = removeLastChar(modifiedValue);
  if (!modifiedValue) return 0;
  else return modifiedValue
  }
export const preventInput = (e,preventedWordOne,preventedType)=>{
  e.preventDefault()

  function avoidNaNTyping(string){
    if(isNaN(string) && !string.indexOf('-')){
      return removeLastChar(e.target.value)
    }
    return e.target.value
  }
  
  if(preventedType==='number'){
  if(e.target.value>1000000)e.target.value=1000000
  e.target.value = avoidNaNTyping(e.target.value)
  }
  
  return e.target.value
}

export const findProvince = (provinceName,previousProvince) =>{
  return provinceName===previousProvince
}

export const mapFee = (valueNumber, positiveParam) => {
  let separatedValues = {};
  if(valueNumber.toString().includes("-")){
      const splited = valueNumber.toString().split("");
      const operator = false;
      splited.shift();
      const number = splited.join("");
      separatedValues = { operator, number }
  } else {
      separatedValues = { operator: positiveParam, number: valueNumber }
  }
  return separatedValues
}