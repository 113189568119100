import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useAuthStore, useonboardingStore } from "../../store";
import { getStoreName } from "../../services/onboarding.service";

import {LogoContainerTiendanube} from '../../components/units/LogoContainer'

import { capitalizeFirstLetter } from "../../services/shared/utils.service";

import { LoginForm } from "../../components/";


export const Welcome = ({storeName}) => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
 
    
  return (
    <div className="container-fluid" id="welcome">
      <div className="row">
        <div className={`col col-xxl-6 welcome-grid ${ecommerce}`}>
            {ecommerce === "tiendanube" ? <LogoContainerTiendanube /> : null}
          <div className="welcome-text">
            <div>
              <h1 className={`main-title ${ecommerce}`}>
                ¡Hola <b>{storeName}</b>!
              </h1>
              <p className="subtitle">
                Gracias por instalarte la aplicación de <span className="fw-bold">Urbano</span> para <span className="fw-bold">{capitalizeFirstLetter(ecommerce)}</span>. Para continuar con el proceso de configuración, es necesario que ya cuentes con una cuenta en <span className="fw-bold">Urbano</span>.
              </p>
            </div>
          </div>
          
        </div>
        <div className="col col-xxl-6 form-col">
          <div className="form-wrapper">
            <h2 className="small-title fw-bold">
              Ingresa tus credenciales de Urbano
            </h2>
            <p className="small-text">
              Conecta tu cuenta de Urbano con {capitalizeFirstLetter(ecommerce)}. ¿No conoces tu Usuario? <a href='#' target="_blank"rel="noreferrer"className="btn-link fw-bold">Contáctanos.</a>
            </p>
            <LoginForm/>

              <p className="medium-text">
                Si todavía no la tienes, <a href='#' target="_blank" rel="noreferrer" className="btn-link fw-bold">crea tu cuenta.</a>
              </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome
