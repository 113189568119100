import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;


export const post = async (endpoint, body, headers) => {
    try {
        const url = `${baseUrl}${endpoint}`;
        const result = await axios(url, {
            method: 'POST',
            headers: headers ? headers : {'content-type': 'application/json'},
            data: body
        });
        return result;

    } catch (error) {
        return error;
    }
}

export const get = async (endpoint, headers) => {
    try {
        const url = `${baseUrl}${endpoint}`;
        const result = await axios(url, {  
            method: 'GET',
            headers: headers ? headers : { 'content-type': 'application/json' }
        });
        return result;

    } catch (error) {
        return error;
    }
}

export const restProvinces  = async (headers) =>{
    try {
        const url = "https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre";
        const result = await axios(url, {  
            method: 'GET',
            headers: headers ? headers : { 'content-type': 'application/json' }
        });
        return result.data.provincias;

    } catch (error) {
        return error;
    }
}


export const restCities  = async (headers,province) =>{
    try {
        const url = `https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&campos=nombre&max=100`;
        const result = await axios(url, {  
            method: 'GET',
            headers: headers ? headers : { 'content-type': 'application/json' }
        });
        return result.data.localidades;

    } catch (error) {
        return error;
    }
}