import React from "react";
import { BrowserRouter,Route,Routes } from "react-router-dom";
import UrbanoRoute from './customers/urbano/UrbanoRoute';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";




export const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="urbano/*" element={<UrbanoRoute/>}/>
        
      </Routes>
    </BrowserRouter>
  );


};
