import React from 'react';
import { useAuthStore } from '../store';


export const OnboardingNav = ({ step}) => {
    const ecommerce = useAuthStore((state) => state.ecommerce.name);
    const pagesTiendanube = ["Creación de tienda", "Métodos de entrega", "Finalización / Guardado"];
    
    return (
      <div className="col-6 onboarding-nav">
        {ecommerce === "tiendanube" && (
          <ul className="pages-titles-list">
            {pagesTiendanube.map((page, index) => (
              <li key={index} className={index + 1 === step ? "selected" : ""}>
                <div className="circle"></div>
                <span>{page}</span>
              </li>
            ))} 
          </ul>
        )}
      </div>
    );
}
