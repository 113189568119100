import React from 'react';
import { useLocation } from "react-router";
import { OnboardingHeader,TimeForm } from '../../../components';
import {setEcommerceURL} from "../../../services/shared/utils.service";
import { capitalizeFirstLetter } from '../../../services/shared/utils.service';

export const Time = () => {
    const location = useLocation();
    const ecommerce= setEcommerceURL(location)
    return (
        <div className={`container-fluid bg-${ecommerce}`} id="time">
            <OnboardingHeader ecommerce={ecommerce} step={2}/>

            <div className="row content deliveryTimeBox">
                <div className="col-12 content-wrapper">
                    <div className="row">
                        <div className="col-6">
                            <h2 className="step-title">Sincronización con {capitalizeFirstLetter(ecommerce)}</h2>
                            <h3 className="step-subtitle">Selecciona a partir de qué acción deseas que se cree la guía en Urbano.</h3>
                        </div>
                        <div className="col-6">
                            <h2 className="step-title">Tarifa</h2>  
                            <h3 className="step-subtitle">Seleccionar tarifa disponible.</h3>
                        </div>
                    </div>
                    <TimeForm/>
                </div>
            </div>
        </div>
    )
}
