import { Welcome } from "../../../../pages/onboarding/Welcome";
import { Route, Routes } from "react-router-dom";
import { DistributionCenter } from "../../../../pages/onboarding/tiendanube/DistributionCenter";
import { Time } from "../../../../pages/onboarding/tiendanube/Time";
import { Finish } from "../../../../pages/onboarding/tiendanube/Finish";
import PanelRoute from "../panelRoute";
import { useAuthStore, useonboardingStore } from "../../../../store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { getStoreName } from "../../../../services/onboarding.service";

export default function UrbanoTNRoute() {
    
  const addEcommerce = useAuthStore((state)=>state.setEcommerce)
  useEffect(() => {
      addEcommerce({
         name: 'tiendanube'
      })
  }, [addEcommerce])

  const location = useLocation();
  const [storeName, setStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const data = useonboardingStore((state)=>state.onboardingData)
  const addData = useonboardingStore((state) => state.addData);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const { store = "" } = queryString.parse(location.search);
  const { token = "" } = queryString.parse(location.search);
    useEffect( () => {
      async function fillStore () {
        setIsLoading(true);
        if(ecommerce==="tiendanube" && store){
          const result = await getStoreName(store);
          if (result.success ) {
            setStoreName(result.data.user.storeData.name.es);
            addAuth({
              user: {
                storeId: store,
                ecommerce: ecommerce,
                storeName: result.data.user.storeData.name.es,
                storeUrl: result.data.user.storeData.url_with_protocol,
                token:token
              },
            });
            addData({
              location: {
                province: result.data.store?.province,
                city: result.data.store?.city,
                street: result.data.store?.street,
                externalNumber: result.data.store?.externalNumber,
                internalNumber: result.data.store?.internalNumber,
                postalCode: result.data.store?.postalCode,
                manager: result.data.store?.manager,
                notes: result.data.store?.notes,
                rate: result.data.store?.rate,
                createAt: result.data.store?.createAt,
                ratePercent:result.data.store?.ratePercent ,
                isPositive: result.data.store?.isPositive
              },
          })
          }
        }
        setIsLoading(false);
      }
      fillStore()
    }, [ecommerce]);
    return (
        <>
            <Routes>
                <Route path="welcome" element={<Welcome storeName={storeName} isLoading={isLoading}/>} />
                <Route path="creation" element={<DistributionCenter/>} />
                <Route path="delivery-time" element={<Time/>} />
                <Route path="finish" element={<Finish/>} />
                <Route path="panel/*"element={<PanelRoute/>} />
            </Routes>
        </>
  );
}