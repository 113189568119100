import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonTag } from '../components/units/shared/Button';
import { setSingleOrderAction } from './grid.service';
import { validateEmail,validateLength,validatePhone } from '../services/shared/utils.service'
import {showError} from '../services/shared/alert.service'

import PDFMerger from 'pdf-merger-js/browser';
import { base64toBlob } from "../utils/base64ToBlob";

export const guideFormatter = (cell, row) => {
    if (cell) {
        return <span className='guideNumber'>{cell}</span>
    } else {
        return <span className='guideNumber'> Error </span>
    }
}

export const dateFormatter = (cell, row) => {
    const orderDate = new Date(row.dateCreated);
    return <span>{`${orderDate.getDate()}/${orderDate.getMonth() + 1}/${orderDate.getFullYear()}`}</span>
}

export const modeFormatter = (cell, row) => {
    return <span>{cell === 'ship' ? 'Domicilio' : 'Sucursal'}</span>
}

export const numberFormatter = (cell, row) => {
    return <span>#{cell}</span>
}

const tooltipMessage = (pickedOrder) =>{
    const {trackingStatus,translation} = pickedOrder
    if(trackingStatus==='PI')
      return 'Tenemos el paquete en nuestros depositos'
    if(trackingStatus==='AS')
      return 'El paquete se encuentra en la sucursal de Urbano para ser procesado'
    if(trackingStatus==='LD')
      return 'El paquete está en camino a tu cliente'
    if(trackingStatus==='DL')
      return 'El paquete ha sido entregado correctamente'
    if(trackingStatus==='ERROR')
      return 'Ha ocurrido un error durante el proceso'
    if(trackingStatus==='CREATED')
      return 'La orden fue creada exitosamente'
    else
        return `Estado actual: ${translation} `
}

const statusConditionalClass = (pickedOrder) => {
    const {overallTab,trackingStatus} = pickedOrder
    if(overallTab==='pending')
        return 'pending-status-badge'
    if(overallTab==='created')
        return 'created-status-badge'
    if(overallTab==='pending')
        return 'delivered-status-badge'
    if(overallTab==='issue')
        return 'issue-status-badge'
    if(trackingStatus==='DL')
        return 'effective-status-badge'
    else
        return 'created-status-badge'
}

export const statusFormatter = (cell, row) => {
    return(
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip ><span>{tooltipMessage(row)}</span></Tooltip>}
        >
          <span className={`badge rounded-pill ${statusConditionalClass(row)} `}>
            {row.translation}
          </span>
        </OverlayTrigger>
    )
  };

  export const options = {
    prePageText: "⬅",
    nextPageText: "➡ ",
    firstPageText: '<<',
    lastPageText:'>>',
    lastPageTitle:'Ir al final',
    firstPageTitle:'Ir al inicio',
    prePageTitle:'Ir un paso atrás',
    hidePageListOnlyOnePage:true,
    withFirstAndLast:false,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  };

  export const filterByTabStatus = (rawData,tab)=>{
    const createdTags = ["LD","PI","AS",'CREATED'];
    const deliveredTags = ['DL'];
    const issueTags= ['ERROR'];
    const pendingTags= ['Solicitud pendiente']
    let pickedTag = []
    if(tab==='created')
    pickedTag= createdTags
    if(tab==='delivered')
    pickedTag= deliveredTags
    if(tab==='issue')
    pickedTag= issueTags
    if(tab==='pending')
    pickedTag= pendingTags
    if(tab==='all')
    return rawData
    return rawData.filter(
      (order)=>pickedTag.includes(order.trackingStatus)
    )
  }



export const singleActionsFormatter = (
  cell,row,
  storeId,
  setIsLoading,
  updateTable,
  tabSelected,
  ecommerce
  ) => {
    const {overallTab,orderId} = row
    const createGuide = ['pending']
    const regenerateGuide = ['issue','delivered','confirmed','created']
    const cancelGuide = ['']
    let text = 'Error'
    let tag
    let operation = 'none'
    if(regenerateGuide.includes(overallTab)){
      text='Volver a Solicitar'
      operation = 'retry'
      tag='repeat-deliver'
    }
    if(createGuide.includes(overallTab)){
      text='Crear Etiqueta'
      operation = 'create'
      tag='create-deliver'
    }
    if(cancelGuide.includes(overallTab)){
      text='Cancelar'
      operation = 'cancel'
      tag='cancel-deliver'
    }
    return(
      text!=='Error' ?
      <ButtonTag
        tag={tag}
        onClickFn={()=>setSingleOrderAction(
          orderId, 
          storeId, 
          setIsLoading, 
          updateTable, 
          tabSelected, 
          ecommerce, 
          operation)}
        innerText={text}
      />
      : null
       )
  }

  export const submitChecker = (name,phone,email) =>{
    if(!validateEmail(email)){
      showError("Su email es inválido. EJ: prueba@urbano.com");
      return false
    }
    if(!validatePhone(phone)){
      showError("Su número de telefono es inválido. EJ: 2235812327");
      return false
    }
    if(!validateLength(name)){
      showError("Su nombre es inválido. EJ: Juan López")
      return false
    }

    if(
      validateEmail(email) &&
      validatePhone(phone) &&
      validateLength(name)
      )
      return true
    else
      return false
  }



export const printLabels = async (orders) => {
  let files = [];
  if(orders.label){
    const label = base64toBlob(orders.label)
    files.push(label)
  }
  else
    {
    for (const order of orders) {
      const label = base64toBlob(order.label)
      files.push(label);
    }
  }
  const merger = new PDFMerger();
  await Promise.all(files.map(async (file) => await merger.add(file)));
  const mergedPdf = await merger.saveAsBlob();
  const url = URL.createObjectURL(mergedPdf);
  window.open(url);
}