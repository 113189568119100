import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";

import Skeleton from 'react-loading-skeleton';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";


// Components
import { PanelActionsModal } from './modal/ModalMassiveActions';
import { ButtonAndToolTip, ButtonTag } from '../../units/shared/Button';
import { useAuthStore } from '../../../store';
//Intern
import { ModalEdit } from './modal/ModalEdit';
import NoData from '../../panel/NoData';

//assets
import refresh from '../../../assets/panel/refresh.svg'
import search from "../../../assets/panel/search.svg";
import gps from "../../../assets/panel/gps.svg";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import ticket from "../../../assets/panel/ticket.svg";



// Services
import {
  guideFormatter,
  dateFormatter,
  modeFormatter,
  numberFormatter,
  statusFormatter,
  options
} from '../../../services/table.service';
import {setSingleOrderAction} from '../../../services/grid.service'
import {getOrders, getMockOrders } from '../../../services/panel.service';
import { showError } from '../../../services/shared/alert.service';

registerLocale("es", es);

export const Table = ({ data, tabSelected, updateTable, printLabels }) => 
{
  const auth = useAuthStore((state) => state.authData);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const [filteredByDateList, setFilteredByDateList] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [ordersIdSelected, setOrdersIdSelected] = useState([])
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsModalProps, setDetailsModalProps] = useState({});


  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(new Date());


  useEffect(() => {
    filterData(data);
  }, [data, tabSelected]);



  const setClientInfo = async (storeId,orderIndex,ecommerce) => {
    setShowDetailsModal(false);
    setIsLoading(true);
    const result = await getOrders(storeId);  
    if (!result.success) {
      setIsLoading(false);
      showError("Conexión fallida");
    } else {
      setDetailsModalProps({
        ecommerce: ecommerce,
        storeId:storeId,
        tabSelected: tabSelected,
        updateTable: updateTable,
        orderId: result.orders[orderIndex]._id,
        handleClose: () => setShowDetailsModal(false),
        orderDetail: result.orders[orderIndex],
      });
      setIsLoading(false);
      setShowDetailsModal(true);
    }
  };

  const selectRowModes = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };


  function onRowSelect(row, isSelected) {
    let order = Array.from(ordersSelected);
    let orderIdArray = Array.from(ordersIdSelected);
    if(row.delivered) return false;
    if (isSelected && !order.includes(row)) {
      order.push(
        row,
        );
        orderIdArray.push(
          row.orderId,
        );
      } else {
        const index = order.indexOf(row)
        if (index > -1) {
          order.splice(index, 1);
        }
      }
    setOrdersSelected(order);
    setOrdersIdSelected(orderIdArray)
  }

  function onSelectAll(isSelected, rows) {
    let order = [];
    let orderIdArray = [];
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        order.push(
          rows[i],
          ); 
        orderIdArray.push(
          rows[i].orderId,
        )
        }

      } 
      if(!isSelected) {
        order = [];
        orderIdArray = [];
      }
    setOrdersSelected(order);
    setOrdersIdSelected(orderIdArray)
    return orderIdArray;
  }


const singleActionsFormatter = (cell,row) => {
    const {overallTab,orderId} = row
    const createGuide = ['pending']
    const regenerateGuide = ['issue','delivered','confirmed','created']
    const cancelGuide = ['']
    let text = 'Error'
    let tag
    let operation = 'none'
    if(regenerateGuide.includes(overallTab)){
      text='Volver a Solicitar'
      operation = 'retry'
      tag='repeat-deliver'
    }
    if(createGuide.includes(overallTab)){
      text='Crear Etiqueta'
      operation = 'create'
      tag='create-deliver'
    }
    if(cancelGuide.includes(overallTab)){
      text='Cancelar'
      operation = 'cancel'
      tag='cancel-deliver'
    }
    return(
      text!=='Error' ?
      <ButtonTag
        tag={tag}
        onClickFn={()=>setSingleOrderAction(
          orderId, 
          auth.user.storeId, 
          setIsLoading, 
          updateTable, 
          tabSelected, 
          ecommerce, 
          operation)}
        innerText={text}
      />
      : null
        )
  }
  // --- date filter --
  const handleDateChange = async (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const filterData = (data) => {
    if(startDate && endDate){
      let filtered = data.filter((order) => {
        let date = new Date(order.dateCreated);
        let afterEndDate = new Date(endDate.toString());
        afterEndDate.setDate(afterEndDate.getDate() + 1);
        return date >= new Date(startDate.toString()) && date < afterEndDate;
      });
      setFilteredByDateList(filtered);
    } else {
      showError("Por favor selecciona una fecha final para el filtro")
      return data
    }
  };

// TODO: SERVICE
  const actionsFormatter = (cell, row) => {
    const {overallTab,key,label,trackingUrl,orderNumber} = row
    const actionsEditArray= ['created','delivered']
    const actionsIssueArray = ['issue']
    const actionsPendingArray = ['pending']
    if (actionsEditArray.includes(overallTab) && trackingUrl){
      return (
        <div className="actions-icons">
          <button
            type="button"
            className="btn"
            onClick={() => printLabels(row)}
          >
              <img src={ticket} alt="ticket" />
            <a href={label} rel="noopener noreferrer" target="_blank" download={`Etiqueta ${orderNumber}`}>
            </a>
          </button>          
          <button
            type="button"
            className="btn"
            onClick={() => setClientInfo(auth.user.storeId,key,ecommerce)}
          >
            <FiEdit />
          </button>
          <a href={trackingUrl} target="_blank" rel="noreferrer">
            <img src={gps} alt="follow" />
          </a>
        </div>
      );
    }
    if(actionsEditArray.includes(overallTab) && !trackingUrl){
      return(
        <div className="actions-icons">
          <button
            type="button"
            className="btn"
            onClick={() => printLabels(row)}
          >
              <img src={ticket} alt="ticket" />
            <a href={label} rel="noopener noreferrer" target="_blank" download={`Etiqueta ${orderNumber}`}>
            </a>
          </button>          
          <button
            type="button"
            className="btn"
            onClick={() => setClientInfo(auth.user.storeId,key,ecommerce)}
          >
            <FiEdit />
          </button>
        </div>
      )
    }
    if (actionsIssueArray.includes(overallTab)) {
      return (
        <div className="actions-icons">
          <button
            type="button"
            className="btn"
            onClick={() => setClientInfo(auth.user.storeId,key,ecommerce)}
          >
            <FiEdit />
          </button>
        </div>
      );
    }
    if (actionsPendingArray.includes(overallTab)) {
      return (
        <div className="actions-icons">
          <button
            type="button"
            className="btn"
            onClick={() => setClientInfo(auth.user.storeId,row.key,ecommerce)}
          >
            <FiEdit />
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => setSingleOrderAction(row.orderId, auth.user.storeId, setIsLoading, updateTable, tabSelected, ecommerce, "cancel")}
          >
            <FiTrash2 />
          </button>
        </div>
      );
    }
  };
  

  // --- needed for enabeling search bar ---
  const { SearchBar } = Search;

  // --- columns structure ---
  const columns = [
    {
      dataField: "actions",
      text: "ACCIONES",
      formatter: actionsFormatter,
      classes: () => {
        return "grid-width-xs";
      },
      headerClasses: () => {
        return "grid-width-xs";
      },
    },
    {
      dataField: "trackingId",
      text: "# GUÍA URBANO",
      formatter: guideFormatter,
      headerClasses: () => {
        return "grid-width-sm-md";
      },
    },
    {
      dataField: "singleActions",
      text: "ETIQUETAS",
      formatter: singleActionsFormatter,
      classes: () => {
        return "grid-width-lg";
      },
      headerClasses: () => {
        return "grid-width-lg";
      },
    },
    {
      dataField: "trackingStatus",
      text: "ESTADO URBANO",
      formatter: statusFormatter,
      classes: () => {
        return "grid-width-md";
      },
      headerClasses: () => {
        return "grid-width-md";
      },
    },
    {
      dataField: "orderNumber",
      text: `# ORDEN ${ecommerce.toUpperCase()}`,
      formatter: numberFormatter,
      headerClasses: () => {
        return "grid-width-lg";
      },
    },
    {
      dataField: "shipping_pickup_type",
      text: "MODO",
      formatter: modeFormatter,
      classes: () => {
        return "grid-width-sm";
      },
      headerClasses: () => {
        return "grid-width-sm";
      },
    },
    {
      dataField: "date",
      text: "FECHA",
      formatter: dateFormatter,
      sort: true,
      classes: () => {
        return "grid-width-sm";
      },
      headerClasses: () => {
        return "grid-width-sm";
      },
    },
  ];


  // --- table ---
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="orderId"
        data={filteredByDateList}
        columns={columns}
        search
      >
        {(props) => (
          <div id="fulltable" className="container-fluid">
            <div className="d-flex flex-column justify-content-start">
              <div className="topBar-container">
                <div className="search-massive-wrapper">
                  <div className="search-container">
                    <SearchBar
                      {...props.searchProps}
                      className="custom-search-field"
                      placeholder="Buscar órdenes"
                      srText="Buscar"
                    />
                    <img src={search} alt="search" />
                  </div>

                  <DatePicker
                    locale="es"
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    onCalendarClose={() => filterData(data)}
                    dateFormat="dd/MM/yy"
                    placeholderText="Filtrar por fecha"
                    className="custom-range-input"
                  />
                  {ordersSelected.length > 0 && (
                      <ButtonAndToolTip
                        ecommerce={ecommerce}
                        type="button"
                        btnTxt="Procesar"
                        showSpinner={false}
                        isDisabled={tabSelected=== 'pending' ? true : false}
                        onClickFn={() => setShowActionsModal(true)}
                        tooltipTxt={`Las ordenes disponibles podrán imprimirse`}
                        tooltipPosition="top"
                        extraClassContainerTooltip="tooltipMassive"
                        tooltipId="massiveOrders"
                        />
                    )
                  }

                  {showActionsModal && (
                    <PanelActionsModal
                      printMassiveOrders={() => printLabels(ordersSelected)}
                      handleClose={() => setShowActionsModal(false)}
                      ordersSelected={ordersSelected}
                      ordersIdSelected={ordersIdSelected}
                      panelTab={tabSelected}
                      ecommerce={ecommerce}
                    />
                  )}
                </div>

                <div className="pagination-container">
                  <span className="pagination-title">Mostrar</span>
                  <button
                    onClick={()=>updateTable(auth.user.storeId)}
                  >
                    <img src={refresh} alt="refresh" />
                  </button>
                </div>
              </div>

              {showDetailsModal && <ModalEdit {...detailsModalProps}/>}

              <div className="scrollTable">
                <BootstrapTable
                  bordered={false}
                  // overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) } //not working, still looks good
                  noDataIndication={<NoData/>}
                  loading={isLoading}
                  headerClasses="custom-header-class"
                  rowClasses="custom-row-class"
                  bodyClasses="custom-body-class"
                  wrapperClasses='custom-wrapper-class'
                  selectRow={selectRowModes}
                  sort={{ dataField: "date", order: "desc" }}
                  {...paginationTableProps}
                  {...props.baseProps}
                />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton count={1} height={100} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={40} style={{ marginBottom: "10px" }} />
          <Skeleton count={10} height={30} style={{ marginBottom: "10px" }} />
          
          <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={60} style={{ marginBottom: "10px" }} />
        </>
      ) : (
        <PaginationProvider 
        pagination={paginationFactory(options)}>
          {contentTable}
        </PaginationProvider>
      )}
    </>
  );
      }