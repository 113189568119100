import { cancelSingleOrder, createMassiveOrder, deleteMassiveOrder, deliverSingleOrder, retryMassiveOrder, retrySingleOrder } from "./panel.service";
import { showError, showSuccess } from "./shared/alert.service";

// Prepare body and set actions for single orders

export const setSingleOrderAction = async(
  orderId, 
  storeId, 
  setIsLoading, 
  updateTable, 
  tabSelected, 
  ecommerce, 
  action
  ) => {
    setIsLoading(true);
    let ordersArr = {
      orderIds: [
        orderId
      ]
    }
    let result = {};
    if(action==="create"){
      result = await deliverSingleOrder(ordersArr, storeId, ecommerce)
    }
    if(action==="cancel"){
      result = await cancelSingleOrder(ordersArr, storeId, ecommerce)
    }
    if(action==="retry"){
      result = await retrySingleOrder(ordersArr, storeId, ecommerce)
    }
    if(result && result?.success){
      showSuccess(result?.message);
      await updateTable(storeId, tabSelected);
    }
    if(!result?.success){
        showError(result?.message);
    }
    setIsLoading(false);
}

// Prepare body and set actions for multiple orders

export const setMassiveOrdersActions = async (ordersSelected, storeId, tabSelected, setIsLoading, updateTable, setOrdersSelected, ecommerce, action) => {
    setIsLoading(true);
    let ordersArr = {
      orderIds: ordersSelected,
    }
    let result = {};
    if(action==="create"){
      result = await createMassiveOrder(ordersArr, storeId, ecommerce)
    }
    if(action==="cancel"){
      result = await deleteMassiveOrder(ordersArr, storeId, ecommerce)
    }
    if(action==="retry"){
      result = await retryMassiveOrder(ordersArr, storeId, ecommerce)
    }
    if (result && !result?.success) {
      showError(`${result?.message}`);
    }
    if (result && result.success) {
      showSuccess(`${result?.message}`);
      await updateTable(storeId, tabSelected);
    }
    setIsLoading(false);
    setOrdersSelected([]);
};
