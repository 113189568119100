import React,{ useEffect } from "react";
import { Route, Routes} from "react-router-dom";
import { useAuthStore } from "../../../store";
import UrbanoTNRoute from './tiendanube/UrbanoTNRoute'



export default function UrbanoRoute() {
  return (
        <>
          <Routes>
              <Route path="tiendanube/*" element={<UrbanoTNRoute />}/>
          </Routes>
        </>
  );
}
