import { post as restPost, get as restGet, restProvinces,restCities } from '../services/shared/rest.service';
import { mockDataForTable } from '../data/mockData';

export const getOrders = async (storeId) => {
    try {
        const endpoint = `/api/v1/panel/orders?store=${storeId}`;
        const result = await restGet(endpoint);

        return result.data;
    } catch (error) {
        return error
    }
};


export const getMockOrders = async (storeId)=>{
    return mockDataForTable[0]
}

export const getProvinces = async () =>{
    try {
        const result = await restProvinces();
        return result;
    } catch (error) {
        return error
    }
}

export const getCities = async (province) =>{
    try {
        const result = await restCities(null,province.id);
        return result;
    } catch (error) {
        return error
    }
}


const setTab = (filteredState) => {
    const createdTabs = ['CREATED','AS','PI','LD']
    const deliveredTabs = ['DL']
    const pendingTabs = []
    const issueTabs = ['ERROR']

    if(createdTabs.includes(filteredState)
    )return "created"
    if(deliveredTabs.includes(filteredState)
    )return "delivered"
    if(pendingTabs.includes(filteredState)
    )return "pending"
    if(issueTabs.includes(filteredState)
    )return "issue"
}
const setTranslation = (status) => {
        const translations = {
            CREATED: 'Creado',
            CREADO: 'Creado',
            DELIVERED: 'Entregado',
            PENDING: 'Solicitud Pendiente',
            PI: 'Pedido Ingresado',
            AS: 'Despacho a sucursal',
            LD: 'Salida a ruta',
            DL: 'Entrega efectiva',
            ERROR: 'Error',
            default: 'default'
        }
    return translations[status];
}

export const handleOrdersList = async( data ) => {
    let formattedOrders = [];
    let key = 0;
    for(const order of data){
        formattedOrders.push({
            key: key,
            _id: order._id,
            orderData: order.orderData,
            storeId: order.storeId,
            orderId: order.orderId,
            orderNumber: order.orderNumber,
            shipping_pickup_type:order.shipping_pickup_type,
            dateCreated: order.dateCreated,
            __v:order.__v,
            trackingId: order.trackingId,
            trackingStatus: order.trackingStatus,
            trackingUrl:order.trackingUrl,
            overallTab: setTab(order.trackingStatus),
            translation: setTranslation(order.trackingStatus),
            label: order.label,
        })
        key++;
    }
    return formattedOrders
}

export const retryOrder = async (cartId, storeId) => {
    try {
        const endpoint = `/api/panel/retry_order`;
        const body = {
            store_id: storeId,
            cart_id: cartId
        };
        const result = await restPost(endpoint, body);

        return result.data;
    } catch(error) {
        return error;
    }
};

// SINGLE ORDER
    export const deliverSingleOrder = async (ordersArr, storeId, ecommerce) => {
        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/fulfill-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
                return result.data;
            } catch(error) {
                return error;
            }
        }
    };

    export const cancelSingleOrder = async (ordersArr, storeId, ecommerce) => {
        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/cancel-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
        
                return result.data;
            } catch(error) {
                return error;
            }
        }
            
    };

    export const retrySingleOrder = async (ordersArr, storeId, ecommerce) => {

        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/fulfill-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
        
                return result.data;
            } catch(error) {
                return error;
            }
        }   

    };
// END OF SINGLE ORDER

// INFO MODAL ON EACH CLIENT

export const getClientDetail = async(orderId, storeId, ecommerce) => {
    if(ecommerce==="tiendanube"){
        try {
            const endpoint = `/api/v1/panel/order-info?store=${storeId}&orderId=${orderId}`;
            const result = await restGet(endpoint);
            return result.data;
        } catch (error) {
            return error
        } 
    }
}

export const getClientDetailMock = async(OrderId,storeId,ecommerce)=>{
    if(ecommerce==="tiendanube"){
        return mockDataForTable
    }
}

export const saveClientInfo = async (params, storeId, ecommerce) => {
    const body= {
        ...params,
        orderId:params.updatedInfo._id
    }
    if(ecommerce==="tiendanube"){
        try {
            const endpoint = `/api/v1/panel/edit-order?store=${storeId}`;
            const result = await restPost(endpoint, body);
            return result.data;

        } catch (error) {
            return error;
        }
    } 
}
// MASSIVE ORDERS
    export const createMassiveOrder = async (ordersArr, storeId, ecommerce) => {

        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/fulfill-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
        
                return result.data;
            } catch(error) {
                return error;
            }
        }
    }

    export const deleteMassiveOrder = async (ordersArr, storeId, ecommerce) => {

        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/cancel-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
        
                return result.data;
            } catch(error) {
                return error;
            }
        }
    }

    export const retryMassiveOrder = async (ordersArr, storeId, ecommerce) => {

        if(ecommerce==="tiendanube"){
            try {
                const endpoint = `/api/v1/panel/fulfill-orders?store=${storeId}`;
                const result = await restPost(endpoint, ordersArr);
        
                return result.data;
            } catch(error) {
                return error;
            }
        }
    }
// END OF MASSIVE ORDERS