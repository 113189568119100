import React, { useState } from "react";
import info from "../../../assets/UI/info.svg";

export const InputsSimple = ({
  value,
  id,
  type,
  placeholder,
  labelTxt,
  onChangeFn,
  onBlurFn,
}) => {
  return (
    <div className="mb-3 mb-xxl-4">
      <label htmlFor={id} className="form-label">
        {labelTxt}
      </label>
      <input
        type={type}
        className="form-control padding-lg"
        id={id}
        placeholder={placeholder}
        onChange={onChangeFn}
        value={value}
        onBlur={onBlurFn}
      />
    </div>
  );
};


export const InputsWithTooltip = ({
  value,
  id,
  type,
  placeholder,
  labelTxt,
  onChangeFn,
  tooltipTxt,
  onBlurFn,
  min,
  max,
  disabled,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="mb-3 w-100">
      <label htmlFor={id} className="form-label label-bold">
        {labelTxt}
      </label>
      <div className="icon-parent">
        {type === "number" ? (
          <input
            type="number"
            className="form-control"
            id={id}
            placeholder={placeholder}
            onChange={onChangeFn}
            onBlur={onBlurFn}
            value={value}
            min={min}
            max={max}
            disabled={disabled}
          />
        ) : (
          <input
            type={type}
            className="form-control"
            id={id}
            placeholder={placeholder}
            onChange={onChangeFn}
            onBlur={onBlurFn}
            value={value}
            disabled={disabled}
          />
        )}

        {tooltipTxt && (
          <div className="tooltip-parent">
            <img
              src={info}
              alt="information"
              className="icon-child"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />

            <div className={`tooltip-child ${showTooltip && "display"}`}>
              <span>{tooltipTxt}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const InputsSelect = ({
  id,
  labelTxt,
  onChangeFn,
  arrayList=[],
  previouslySelected,
  isLoading,
}) => {

  return (
    !isLoading &&
    <div className="select-wrapper mb-3">
      <label htmlFor={id} className="form-label label-bold">
        {labelTxt}
      </label>

      <select
        className="form-select"
        aria-label={labelTxt}
        id={id}
        onChange={onChangeFn}
      >
        {previouslySelected ? (
          <option className="form-select-option" disabled>
            Seleccionar...
          </option>
        ) : (
          <option className="form-select-option" selected disabled>
            Seleccionar...
          </option>
        )}
        {arrayList.map((item, index) => (
          <option
            key={`${index}`}
            className="form-select-option"
            value={item}
            defaultValue={item === previouslySelected ? true : false}
          >
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export const InputsRadio = ({
  id,
  name,
  value,
  labelTxt,
  onChangeFn,
  checked,
  disabled,
}) => {
  return (
    <div className="form-check mb-3 mb-xxl-4">
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChangeFn}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {labelTxt}
      </label>
    </div>
  );
};



export const InputsDuration = ({
  ecommerce,
  value1,
  value2,
  id1,
  id2,
  units,
  labelTxt,
  onChangeFn,
  onBlurFn,
  min1,
  min2,
  max1,
  max2,
}) => {
  return (
    <div className={`from-to-wrapper ${ecommerce}`}>
      <h4 className="form-duration-label label-bold">{labelTxt}</h4>
      <div className="mt-2">
        <input
          type="number"
          className="form-control"
          id={id1}
          onChange={onChangeFn}
          value={value1}
          min={min1}
          max={max1}
        />
        <small>a</small>
        <input
          type="number"
          className="form-control"
          id={id2}
          onChange={onChangeFn}
          value={value2}
          min={min2}
          max={max2}
          onBlur={onBlurFn}
        />
        <small>{units}</small>
      </div>
    </div>
  );
};

export const InputsSwitch = ({ id, value, checked, onChangeFn, labelTxt }) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        value={value}
        onChange={onChangeFn}
        checked={checked}
      />
      <label className="form-check-label label-bold" htmlFor={id}>
        {labelTxt}
      </label>
    </div>
  );
};

export const InputsCheckbox = ({ id, value, onChangeFn, labelTxt }) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        value={value}
        onChange={onChangeFn}
      />
      <label className="form-check-label" htmlFor={id}>
        {labelTxt}
      </label>
    </div>
  );
};

export const InputsTextarea = ({
  value,
  id,
  placeholder,
  labelTxt,
  onChangeFn,
  onBlurFn,
  disabled,
  rows,
  cols,
  extraClassTextArea,
}) => {
  return (
    <div className="mb-1 w-100">
      <label htmlFor={id} className="form-label label-bold">
        {labelTxt}
      </label>
      <div className="icon-parent">
        <textarea
          className={`form-control ${extraClassTextArea}`}
          id={id}
          placeholder={placeholder}
          onChange={onChangeFn}
          onBlur={onBlurFn}
          value={value}
          disabled={disabled}
          rows={rows}
          cols={cols}
          min={1}
          max={100}
        />
      </div>
    </div>
  );
};

export const InputsSolidPlaceHolder = ({
  type,
  value,
  id,
  placeholder,
  labelTxt,
  onChangeFn,
  onBlurFn,
  disabled,
  extraClassContainer,
  extraClassInput,
  unit,
  extraClassUnit
}) => {
  return (
    <div className={`mb-1 input-placeholder-box  ${extraClassContainer}`}>
      <label htmlFor={id} className="form-label label-bold">
        {labelTxt}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChangeFn}
        onBlur={onBlurFn}
        disabled={disabled}
        autoFocus
        className={`inputPlaceHolder ${extraClassInput}`}
      />
      <span className={`unit ${extraClassUnit}`}>{unit}</span>
    </div>
  );
};
