import React, { useEffect, useState } from "react";
import {getOrders, handleOrdersList} from "../../../services/panel.service";
import {
  filterByTabStatus,printLabels
} from '../../../services/table.service'
import { Table } from "./Table";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useLocation } from "react-router";
import queryString from 'query-string';

export const GridSetter = ({ tab }) => {
  const location = useLocation()
  const [tabSelected, setTabSelected] = useState("");
  const { store = "" } = queryString.parse(location.search);
  const [ordersFilteredByStatus, setOrdersFilteredByStatus] = useState([]);
  const [isLoading, setisLoading] = useState(false);


  const GetFilterAndMapOrders = async (storeId) => {
    if (tabSelected !== tab) {
      setTabSelected(tab);
    }
    setisLoading(true)
    
    setOrdersFilteredByStatus([]);
    if (storeId) {
      setisLoading(true);
      const rawData = await getOrders(storeId);
      let filteredData = await filterByTabStatus(rawData.orders, tab);
      if (filteredData) {
        const mappedData = await handleOrdersList(filteredData);
        setOrdersFilteredByStatus(mappedData);
      }
      setisLoading(false);
    }
  };
  useEffect(() => {
    GetFilterAndMapOrders(store);
    
  },[]);


  return (
    <div className="grid-table">
      {isLoading ? (
        <div className='skeleton-holder'>
          <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
          <Skeleton count={10} height={70} style={{ marginBottom: "10px" }} />
          <Skeleton count={2} height={70} style={{ marginBottom: "10px" }} />
          <Skeleton count={4} height={60} style={{ marginBottom: "10px" }} />
        </div>
      ) : (
        <Table
          data={ordersFilteredByStatus}
          tabSelected={tabSelected}
          updateTable={GetFilterAndMapOrders}
          printLabels={printLabels}
        />
      )}
    </div>
  );
};
