import React from 'react'
import { TimeFormPanel } from '../../../components/panel/TimeFormPanel';

export const DeliveryTimesConfig = () => {
    
    return (
        <div id="deliveryConfigPanel">
            <div className="content-wrapper">
            <TimeFormPanel/>    
                
            </div>
        </div>
    )
}
