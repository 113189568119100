import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { saveClientInfo } from "../../../../services/panel.service";
import { showError, showSuccess } from "../../../../services/shared/alert.service";
import {
  checkEmailFormat,
  checkLength,
  checkPhoneFormat,
} from "../../../../services/shared/utils.service";
import { submitChecker } from "../../../../services/table.service";
import { useAuthStore } from "../../../../store";
import { ButtonSmall } from "../../../units/shared/Button";

import {  InputsWithTooltip } from "../../../units/shared/Inputs";

export const ModalEdit = ({
  orderId,
  storeId,
  tabSelected,
  updateTable,
  handleClose,
  orderDetail,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
  const [body, setBody] = useState({
    ...orderDetail,
    _id: orderDetail?._id,
    shipping_address: {
      ...orderDetail?.orderData?.shipping_address,
    },
    customer: {
      ...orderDetail?.orderData?.customer,
    },
  });

  const handleChange = (e) => {
    setBody({
      ...body,
      shipping_address: {
        ...body.shipping_address,
        [e.target.id]: e.target.value,
      },
      customer: { ...body.customer, [e.target.id]: e.target.value },
    });
  };

  const handleSubmit = async () => {
    if (
      submitChecker(body.customer.name,body.shipping_address.phone,body.customer.email)
    ) {
      let composedBody = {
        orderId: orderId,
        updatedInfo: body,
      };
      const result = await saveClientInfo(composedBody, storeId, ecommerce);
      if (result && result.success) {
        showSuccess(result.message);
        updateTable(storeId, tabSelected);
      }
      if (!result.success) {
        showError("Ha ocurrido un error al salvar la información del cliente");
      }
      handleClose();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        className="rounded-3"
        id="panel-edit-modal"
        show={true}
        backdrop={true}
        onHide={handleClose}
        centered
        size="lg"
        scrollable
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title medium-text">
            Editar información
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body columns">
          <div>
            <InputsWithTooltip
              value={body.customer.name}
              id="name"
              type="text"
              placeholder={
                body.customer.name ? body.customer.name : "Ej: Juan Pérez"
              }
              labelTxt="Nombre y apellido del cliente*"
              onChangeFn={handleChange}
              onBlurFn={checkLength}
              disabled={false}
            />

            <InputsWithTooltip
              value={body.shipping_address.locality}
              id="locality"
              type="text"
              placeholder={
                body.shipping_address.locality
                  ? body.shipping_address.locality
                  : "Estado"
              }
              labelTxt="Estado*"
              onChangeFn={handleChange}
              disabled={true}
            />
            <InputsWithTooltip
              value={body.shipping_address.city}
              id="city"
              type="text"
              placeholder={
                body.shipping_address.city
                  ? body.shipping_address.city
                  : "Ciudad"
              }
              labelTxt="Ciudad*"
              onChangeFn={handleChange}
              disabled={true}
            />

            <InputsWithTooltip
              value={body.shipping_address.zipcode}
              id="zipcode"
              type="text"
              placeholder={
                body.shipping_address.zipcode
                  ? body.shipping_address.zipcode
                  : "Ej: 7600"
              }
              labelTxt="Código Postal*"
              onChangeFn={handleChange}
              disabled={true}
            />
          </div>
          <div>
            <InputsWithTooltip
              value={body.shipping_address.number}
              id="number"
              type="text"
              placeholder={
                body.shipping_address.number
                  ? body.shipping_address.number
                  : "Ej: 5A"
              }
              labelTxt="Calle*"
              onChangeFn={handleChange}
              disabled={true}
            />

            <InputsWithTooltip
              value={body.shipping_address.phone}
              id="phone"
              type="text"
              placeholder={
                body.shipping_address.phone
                  ? body.shipping_address.phone
                  : "Ej: +5492235551111"
              }
              labelTxt="Teléfono*"
              onChangeFn={handleChange}
              onBlurFn={(e) => {
                checkLength(e);
                checkPhoneFormat(e);
              }}
              disabled={false}
            />

            <InputsWithTooltip
              value={body.shipping_address.floor}
              id="floor"
              type="text"
              placeholder={
                body.shipping_address.floor
                  ? body.shipping_address.floor
                  : "Ej: 5A"
              }
              labelTxt="Número de Departamento"
              onChangeFn={handleChange}
              disabled={false}
            />
            <InputsWithTooltip
              value={body.customer.email}
              id="email"
              type="text"
              placeholder={
                body.customer.email ? body.customer.email : "cliente@correo.com"
              }
              labelTxt="Correo electrónico del Cliente*"
              onChangeFn={handleChange}
              onBlurFn={(e) => {
                checkLength(e);
                checkEmailFormat(e);
              }}
              disabled={false}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <ButtonSmall
            ecommerce={ecommerce}
            type="button"
            btnTxt="Cancelar"
            showSpinner={false}
            isDisabled={false}
            onClickFn={() => handleClose()}
            extraClass="btn-outline-primary"
          />

          <ButtonSmall
            ecommerce={ecommerce}
            type="button"
            btnTxt="Guardar cambios"
            showSpinner={isLoading ? true : false}
            isDisabled={false}
            onClickFn={() => handleSubmit()}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
