import React, { useState,useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAuthStore, useonboardingStore } from "../../store";
import {
  createStorehouse,
  validateLogin,
} from "../../services/onboarding.service";
import { showError, showSuccess } from "../../services/shared/alert.service";
import { ButtonSmall} from "../units/shared/Button";
import { InputsSelect, InputsWithTooltip } from "../units/shared/Inputs";
import {preventInput,capitalizeFirstLetter, findProvince} from "../../services/shared/utils.service";
import { setEcommerceURL } from "../../services/shared/utils.service";
import { getCities,getProvinces } from "../../services/panel.service";


export const CreationForm = () => {
  let navigate = useNavigate();
  const addData = useonboardingStore((state) => state.addData);
  const store = useonboardingStore((state) => state.onboardingData.location);
  const auth = useAuthStore((state) => state.authData);
  const location = useLocation();

  const ecommerce = setEcommerceURL(location)
  const bannedProvinces = ["94"]
  const bannedCities= []
  const [filteredProvinces,setFilteredProvinces] = useState([])
  const [provinceList,setProvinceList] = useState([])
  const [cityList,setCityList] = useState([])
  const [isSubmit,setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [body, setBody] = useState({
    location: {
      province: store?.province,
      city: store?.city,
      street: store?.street,
      externalNumber: store?.externalNumber,
      internalNumber: store?.internalNumber,
      postalCode: store?.postalCode,
      manager: store?.manager,
      notes: store?.notes
    },
  });
// PROVINCE AND CITY HANDLING
    useEffect(() => {
      setIsLoading(true)
      fetchProvinces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
      setIsLoading(false)

    }, [filteredProvinces])

    const fetchProvinces = async() =>{
      const provinces = await getProvinces()
      const unbannedProvinces= provinces.filter(prov=>!bannedProvinces.includes(prov.id))
      const provinceName = unbannedProvinces.map(e=>e.nombre)
      provinceName.sort()
      if(provinceName.indexOf(store?.province)>0 && !isSubmit){
        provinceName.splice(provinceName.indexOf(store?.province),1)
        provinceName.unshift(store?.province)
      }
      setProvinceList(unbannedProvinces)
      setFilteredProvinces(provinceName)
      setCityList([store?.city])
    }
    const fetchCities = async(province) => {
      const pickedProvince = provinceList.filter(prov=>prov.nombre===province)
      const unbannedCities= pickedProvince.filter(city=>!bannedCities.includes(city.id))
      const cities = await getCities(...unbannedCities)
      const cityName = cities.map(e=>e.nombre)
      const formattedCities = cityName.map(e=>capitalizeFirstLetter(e.toLowerCase()))
      formattedCities.sort()
      setCityList(formattedCities)
    }
//END


  const handleChange = (e) => {
    const formArray = ['notes','manager','city','street','externalNumber','internalNumber']
    if (formArray.includes(e.target.id)) {
      setBody({
        ...body,
        location: { ...body.location, [e.target.id]: e.target.value },
      });
    }

    if(e.target.id==='province'){
      fetchCities(e.target.value)
      setBody({
        ...body,
        location: { ...body.location, city:null, [e.target.id]: e.target.value },
      });
    }

    if (e.target.id === "postalCode") {
      e.target.value = preventInput(e, "", "number");
      setBody({
        ...body,
        location: { ...body.location, [e.target.id]: e.target.value },
      });
    }
  };

  const handleCreation = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateLogin(body)){
      try {
        const result = await createStorehouse(body, auth.user.storeId);
        if (result && result.data.success) {
          addData({
            store: {
              ...store,
              province: body.location.province,
              city: body.location.city,
              street: body.location.street,
              externalNumber: body.location.externalNumber,
              internalNumber: body.location.internalNumber,
              postalCode: body.location.postalCode,
              manager: body.location.manager,
              notes: body.location.notes,
            },
          });
          showSuccess(result.message);
          navigate(`/urbano/${ecommerce}/delivery-time`);
        }
        if (result.error) {
          showError(result.error);
        }
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        showError("Ocurrió un error en la conexión");
      }
    } else {
      setIsLoading(false);
      showError("Verifique haber completado todos los campos");
    }
  };

  return (
    <form className="col" onSubmit={handleCreation}>
      <div className="form-grid-two-columns">
        <div className="d-flex flex-column form-section">
          <InputsWithTooltip
            value={body.location.street}
            id="street"
            type="text"
            placeholder="Calle"
            labelTxt="Calle*"
            onChangeFn={handleChange}
            tooltipTxt="Calle donde se encuentra ubicada la tienda"
          />
          <InputsWithTooltip
            value={body.location.externalNumber}
            id="externalNumber"
            type="text"
            placeholder="1835"
            labelTxt="Número de local*"
            onChangeFn={handleChange}
            tooltipTxt="Número de la calle mencionada previamente"
          />
          <InputsWithTooltip
            id="internalNumber"
            value={body.location.internalNumber}
            type="text"
            placeholder="123"
            labelTxt="Número de piso"
            onChangeFn={handleChange}
            tooltipTxt="De ser posible, el piso en el que esta ubicado"
          />
          <InputsWithTooltip
            value={body.location.postalCode}
            id="postalCode"
            type="text"
            placeholder="33142"
            labelTxt="Código postal*"
            onChangeFn={handleChange}
          />
        </div>

        <div className="d-flex flex-column form-section">
          <InputsSelect
            value={body.location.province}
            id="province"
            labelTxt="Provincia*"
            onChangeFn={handleChange}
            arrayList={filteredProvinces}
            previouslySelected={[body.location.province]}
          />
          

          <InputsSelect
            value={body.location.city}
            id="city"
            labelTxt="Ciudad*"
            onChangeFn={handleChange}
            arrayList={cityList}
            previouslySelected={[body.location.city]}
          />

          <InputsWithTooltip
            value={body.location.notes}
            id="notes"
            type="text"
            placeholder="Ej. La tienda se encuentra en un segundo piso"
            labelTxt="Instrucciones de llegada*"
            onChangeFn={handleChange}
            tooltipTxt="Detalle adicional sobre la tienda"
          />

          <InputsWithTooltip
            value={body.location.manager}
            id="manager"
            type="text"
            placeholder="Marcos Reynoso"
            labelTxt="Nombre de quién prepara los pedidos*"
            onChangeFn={handleChange}
            tooltipTxt="Nombre y Apellido de la persona encargada de preparar y despachar los envíos"
          />
        </div>
      </div>
      <ButtonSmall
        type="submit"
        btnTxt="Siguiente"
        extraClass="blackButton"
        showSpinner={isLoading}
        isDisabled={validateLogin(body)
            ? false
            : true
        }
      />
    </form>
  );
};
