const NoData = () => {
    return (
        <div style={{color:'gray',padding: 100}}>
            <h3>Por el momento no tienes datos disponibles para mostrar.</h3>
            <h5><i>Verifica haber colocado la fecha correctamente.</i></h5>
        </div>
    )
}

export default NoData
