import React from 'react'
import { useAuthStore } from '../store';
import { OnboardingNav } from './OnboardingNav';
import { LogoContainerTiendanube} from './units/LogoContainer';


export const OnboardingHeader = ({ step }) => {
  const ecommerce = useAuthStore((state) => state.ecommerce.name);
    return (
      <div className="row custom-navbar">
        <div className="col-6">
        {ecommerce === "tiendanube" && <LogoContainerTiendanube />}
        </div>
        <OnboardingNav step={step} />
        <div className="col-1"></div>
      </div>
    );
}


