import React,{useState} from 'react';
import { useLocation } from "react-router";
import {OnboardingHeader} from '../../../components'
import { CreationForm } from '../../../components/tiendanube/CreationForm';
import {setEcommerceURL} from "../../../services/shared/utils.service";

export const DistributionCenter = () => {
    const location = useLocation();
    const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
    return (
        <div className={`container-fluid bg-${ecommerce}`} id="distributionCenter">
            <OnboardingHeader step={1} ecommerce={ecommerce}/>
            <div className="row content">
                <div className="col-12 content-wrapper">
                    <h1 className="step-title">Creación de tienda</h1>
                    <h2 className="step-subtitle">Completa los siguiente casilleros con la ubicación de tu tienda</h2>
                    <CreationForm/>
                </div>
            </div>
        </div>
    )
}
